import React from 'react';
import { graphql } from 'gatsby';
import { Container, Box } from '@chakra-ui/react';

import SEO from 'timelesstime-ui/components/seo';
import Html from 'timelesstime-ui/components/html';
import Breadcrumbs from 'timelesstime-ui/components/breadcrumbs';
import Heading from 'timelesstime-ui/components/heading';
import PageLayout from '../components/layout';

const Page = ({ data: { contentfulPage: page } }) => (
  <PageLayout>
    <SEO
      title={page.title}
      keywords={page.fields.keywords}
      description={page.metaDescription}
      canonicalPath={page.fields.path}
      thumbnail={page.jsonLdThumbnailImage}
    />

    <Container maxW="container.lg" mt={2} mb={4}>
      <Breadcrumbs
        color="gray.500"
        crumbs={[
          {
            url: page.fields.path,
            title: page.title,
          },
        ]}
      />
    </Container>

    <Container maxW="container.lg" mt={8} mb={12}>
      <Heading as="h1">{page.title}</Heading>

      <Box as="section">
        <Html source={page.fields.contentHtml} headerLevelStart={1} />
      </Box>
    </Container>
  </PageLayout>
);

export const query = graphql`
  query SinglePageQuery($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      title
      heading
      slug
      heading
      metaDescription
      jsonLdFeaturedImage: featuredImage {
        ...JsonLdFeaturedImage
      }
      jsonLdThumbnailImage: featuredImage {
        ...JsonLdThumbnailImage
      }
      fields {
        path
        url
        writtenAt
        isNew
        keywords
        contentHtml
      }
    }
  }
`;

export default Page;
